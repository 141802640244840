<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <b-row v-if="!editFRAMEWORKEXCEPTION">
          <b-col>
          </b-col>
          <b-col>
            <b-form-select
                v-model="selectedFrameworkItemID"
                :options="frameworkItems"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>      

      <div v-if="!editFRAMEWORKEXCEPTION">
        <b-table
          striped
          hover
          sort-icon-left          
          :items="currentFRAMEWORKACTIONsDataItems"
          :fields="frameworkexceptionFields"
        >
          <template #cell(framework_requirement_response_id)="data">
            {{getFrameworkLinkedTo(data.item)}}
          </template>        
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id)"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
      </div>

      <div v-if="editFRAMEWORKEXCEPTION">
        <ActionsInformation
          :record="editFRAMEWORKEXCEPTIONRecord"
          :frameworkItems="frameworkItems"
          :selectedFrameworkItemID="selectedFrameworkItemID"          
          @canceled="editFRAMEWORKEXCEPTION = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_FRAMEWORKEXCEPTIONS,
  DELETE_FRAMEWORKEXCEPTION_DETAILS,
} from "@/core/services/store/frameworkexceptions.module";

import ActionsInformation from "@/view/pages/work/tasks/TasksInformation";

export default {
  name: "manageframeworkexceptions",
  components: {
    ActionsInformation,
  },
  data() {
    return {
      selectedFrameworkID:'',
      selectedFrameworkItemID:'',      
      editFRAMEWORKEXCEPTION: false,
      editFRAMEWORKEXCEPTIONRecord: [],

      frameworkexceptionFields: [
        { key: "reference_id", label: "Reference", sortable: true },
        { key: "framework_requirement_response_id", label: "Linked To" },        
        { key: "description", label: "Exception" },
        { key: "reason" },
        { key: "system" },
        { key: "exception_date" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Not applicable Register" }]);


    if (this.$route.params.id) {
      this.selectedFrameworkItemID = this.$route.params.id;
    }

    if (this.$route.params.frameworkid) {
      this.selectedFrameworkID = this.$route.params.frameworkid;
    }

    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_FRAMEWORKEXCEPTIONS, {framework_id: this.selectedFramework});
    },
    getFrameworkLinkedTo(item) {
      var retVal = 'n/a';
      if (item.framework_requirement_response_id == null) { 
        retVal = "All";
      } else {
        if (this.currentFramework.objective_items) { 
          this.currentFramework.objective_items.forEach(o => {

            console.log(o);

            if (o.id == item.framework_requirement_response_id) {
                retVal = o.objective_code + ' - ' + o.objective;
            }
            
          });
        }
      }        
      
      return retVal;
    },    
    finished() {
      this.editFRAMEWORKEXCEPTION = false;
      this.editFRAMEWORKEXCEPTIONRecord = [];
      setTimeout(() => {
        this.getData();
      }, 500);

    },
    deleteRecord(id) {
      console.log("delete");
      if (confirm("Do you really want to delete?")) {
        this.editFRAMEWORKEXCEPTION = false;
        this.$store.dispatch(DELETE_FRAMEWORKEXCEPTION_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);

      }
    },
  },
  computed: {
    ...mapGetters(["currentFRAMEWORKEXCEPTIONsData","selectedFramework","currentFramework"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    currentFRAMEWORKACTIONsDataItems() {

      var targetListResult = this.currentFRAMEWORKEXCEPTIONsData;

            console.log('this.selectedFrameworkItemID');
            console.log(this.selectedFrameworkItemID);
  
      if (this.currentFRAMEWORKEXCEPTIONsData.length > 0) {
        if (this.selectedFrameworkItemID != '') {
          targetListResult = [];
          this.currentFRAMEWORKEXCEPTIONsData.forEach(o => {
            if (o.framework_requirement_response_id == this.selectedFrameworkItemID) {
              targetListResult.push(o);
            }
            
          });
        }
      }
        return targetListResult;
    },
    frameworkItems() {
      var results = [];
      results.push({
        value: '',
        text: 'All',
      });
      if (this.currentFramework.framework_items) {      
        this.currentFramework.framework_items.forEach((item) => {
          results.push({
            value: item.id,
            text: item.category + '-' + item.objective_code + ' - ' + item.objective,
          });
        });
      }
      return results;
    },    
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
